import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import Icon from 'components/icon';
import Link from 'components/link';
import {
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table';
import { ElectionGroup } from 'interfaces';

import ElectionGroupTableRow from './ElectionGroupTableRow';
import ElectionTableRow from './ElectionTableRow';

const useStyles = createUseStyles({
  votesOutsideCensusColumnHeader: {
    width: '8rem',
  },
});

function filterElectionGroups(electionGroups: ElectionGroup[]) {
  // Convert status to "sortable" values
  const statusMapping = {
    ongoing: 5,
    announced: 4,
    published: 4,
    draft: 3,
    inactive: 2,
    cancelled: 2,
    closed: 1,
  };

  const getStatusSorting = (electionGroup: ElectionGroup): number => {
    if (electionGroup.status === 'multipleStatuses') {
      const statuses = electionGroup.elections.map(
        (election) => election.status
      );
      if (statuses.includes('ongoing')) {
        return 5;
      }
      if (statuses.includes('announced') || statuses.includes('published')) {
        return 4;
      }
      if (statuses.includes('draft')) {
        return 3;
      }
      if (statuses.includes('inactive') || statuses.includes('cancelled')) {
        return 2;
      }
      return 1;
    }
    return statusMapping[electionGroup.status];
  };

  const getElectionGroupEndTime = (electionGroup: ElectionGroup) => {
    if (electionGroup.elections.length === 1) {
      return electionGroup.elections[0].end;
    }
    return moment
      .max(...electionGroup.elections.map((e) => moment(e.end)))
      .toISOString();
  };

  const sortedGroups = electionGroups.sort(
    (a: ElectionGroup, b: ElectionGroup) => {
      if (getStatusSorting(a) < getStatusSorting(b)) {
        return 1;
      }
      if (getStatusSorting(a) > getStatusSorting(b)) {
        return -1;
      }

      // Sort on end time if status is equal.
      return getElectionGroupEndTime(b).localeCompare(
        getElectionGroupEndTime(a)
      );
    }
  );

  return sortedGroups;
}

export default function ManageElectionsTable({
  electionGroups,
}: {
  electionGroups: ElectionGroup[];
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedElection, setSelectedElection] = useState<string>('');

  const setSelected = (electionId: string) => {
    if (electionId === selectedElection) {
      setSelectedElection('');
    } else {
      setSelectedElection(electionId);
    }
  };

  if (electionGroups.length === 0) {
    return <p>{t('election.noManageableElections')}</p>;
  }

  const sortedElectionGroups = filterElectionGroups(electionGroups);

  return (
    <Table>
      <TableHeader key="thead">
        <TableHeaderRow>
          <TableHeaderCell />
          <TableHeaderCell>{t('election.elections')}</TableHeaderCell>
          <TableHeaderCell>{t('election.opens')}</TableHeaderCell>
          <TableHeaderCell>{t('election.closes')}</TableHeaderCell>
          <TableHeaderCell>
            <div className={classes.votesOutsideCensusColumnHeader}>
              {t('election.votesOutsideCensus')}
            </div>
          </TableHeaderCell>
          <TableHeaderCell>{t('election.electionStatus')}</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      {sortedElectionGroups.map((electionGroup) => {
        let selected = false;
        if (selectedElection === electionGroup.id) {
          selected = true;
        }
        return (
          <TableBody key={electionGroup.id}>
            <ElectionGroupTableRow
              elGrp={electionGroup}
              selectAction={setSelected}
              selected={selected}
            />
            {selected &&
              electionGroup.type === 'multiple_elections' &&
              electionGroup.elections.map((el) => {
                if (!el.active) {
                  return null;
                }
                return <ElectionTableRow key={el.id} election={el} />;
              })}
            {selected && (
              <TableRow thickBorder noHoverBg>
                <TableCell greyBg />
                <TableCell colspan={5} greyBg alignRight>
                  <Link
                    to={`/admin/elections/${electionGroup.id}/info`}
                    marginRight
                  >
                    <Icon type="forwardArrow" marginRight />
                    {t('election.goTo')}
                    &nbsp;
                    {t('election.settings')}
                  </Link>
                  <Link to={`/admin/elections/${electionGroup.id}/status`}>
                    <Icon type="forwardArrow" marginRight />
                    {t('election.goTo')}
                    &nbsp;
                    {t('election.status')}
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        );
      })}
    </Table>
  );
}
